import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompleteResolver, IndexedDbGuard, PublicTranslationResolver } from '@capturum/complete';
import { AuthGuard } from '@capturum/auth';
import { BasicLayoutComponent } from '@shared/layouts/basic-layout/basic-layout.component';
import { AdminRoutes, AuthRoutes, PublicRoutes } from '@core/enums/routes';
import { PublicResponseFormModule } from '@modules/public-response-form/public-response-form.module';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ConfigOverviewComponent } from '@shared/components/config-overview/config-overview.component';
import { loginRoutes } from '@capturum/login';

const routes: Routes = [
  {
    ...loginRoutes[0],
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: PublicTranslationResolver,
    },
    children: [...loginRoutes[0].children],
  },
  {
    path: '',
    canActivate: [IndexedDbGuard, AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'admin',
        pathMatch: 'full',
      },
      {
        path: AdminRoutes.admin,
        component: BasicLayoutComponent,
        resolve: {
          complete: CompleteResolver,
        },
        children: [
          {
            path: '',
            redirectTo: `${AdminRoutes.clients}`,
            pathMatch: 'full',
          },
          {
            path: AdminRoutes.config,
            component: ConfigOverviewComponent,
          },
          {
            path: AdminRoutes.campaigns,
            loadChildren: () => {
              return import('./modules/campaign/campaign.module').then((m) => {
                return m.CampaignModule;
              });
            },
          },
          {
            path: AdminRoutes.clients,
            loadChildren: () => {
              return import('./modules/client/client.module').then((m) => {
                return m.ClientModule;
              });
            },
          },
          {
            path: AdminRoutes.forms,
            loadChildren: () => {
              return import('./modules/form/form.module').then((m) => {
                return m.FormModule;
              });
            },
          },
          {
            path: `${AdminRoutes.forms}/${AdminRoutes.callForm}`,
            loadChildren: () => {
              return import('./modules/call-form/call-form.module').then((m) => {
                return m.CallFormModule;
              });
            },
          },
          {
            path: AdminRoutes.inbox,
            loadChildren: () => {
              return import('./modules/inbox/inbox.module').then((m) => {
                return m.InboxModule;
              });
            },
          },
          {
            path: AdminRoutes.projects,
            loadChildren: () => {
              return import('./modules/project/project.module').then((m) => {
                return m.ProjectModule;
              });
            },
          },
          {
            path: AdminRoutes.suppliers,
            loadChildren: () => {
              return import('./modules/supplier/supplier.module').then((m) => {
                return m.SupplierModule;
              });
            },
          },
          {
            path: AdminRoutes.settings,
            loadChildren: () => {
              return import('./modules/settings/settings.module').then((m) => {
                return m.SettingsModule;
              });
            },
          },
          {
            path: AdminRoutes.tenants,
            loadChildren: () => {
              return import('./modules/tenant/tenant.module').then((m) => {
                return m.TenantModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: 'tenant.manage',
                redirectTo: '/',
              },
            },
          },
          {
            path: AdminRoutes.roles,
            loadChildren: () => {
              return import('./modules/role/role.module').then((m) => {
                return m.RoleModule;
              });
            },
            data: {
              permissions: {
                only: 'role.manage.tenant',
                redirectTo: '/',
              },
            },
          },
          {
            path: AdminRoutes.translations,
            loadChildren: () => {
              return import('./modules/translation/translation.module').then((m) => {
                return m.TranslationModule;
              });
            },
          },
          {
            path: AdminRoutes.users,
            loadChildren: () => {
              return import('./modules/user/user.module').then((m) => {
                return m.UserModule;
              });
            },
          },
          {
            path: AdminRoutes.baseData,
            loadChildren: () => {
              return import('./modules/base-data/base-data.module').then((m) => {
                return m.BaseDataModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: 'base-data-value.manage.tenant',
                redirectTo: '/',
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: PublicRoutes.responseForm,
    loadChildren: () => {
      return PublicResponseFormModule;
    },
  },
  {
    path: '',
    redirectTo: `${AdminRoutes.admin}`,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: `${AuthRoutes.auth}/${AuthRoutes.login}`,
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
